$primary-color: #60b030;
$primary-background-color: #60b030;
$primary-background-color-75: rgba(96,176,48,0.75);
$primary-background-color-50: rgba(96,176,48,0.5);
$primary-background-color-25: rgba(96,176,48,0.25);
$primary-background-color-light: #e6ece3;
$primary-background-color-dark: #2a6308;

$secondary-color: #134391;
$secondary-background-color: #134391;
$secondary-background-color-75: rgba(19,67,145,0.75);
$secondary-background-color-50: rgba(19,67,145,0.5);
$secondary-background-color-25: rgba(19,67,145,0.35);
$secondary-background-color-light: #e7ecf4;


$red-color: rgba(196,32,0,1);
$red-background-color: rgba(196,32,0,1);

$text-color: red;
$title-color: darken($text-color, 5%);
$h1-color: #20215c;

// Cookie
$mainCookieBG: #60b030;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
