.footer{
    position: relative;
    margin-top: 120px;
    text-align: center;
    &:before{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: $primary-background-color-25;
        content: '';
        width: 50%;
        z-index: -1;
    }
    .container{
        padding: 60px 0;
        background-color: $primary-background-color-light;
    }
    .footer-content{
        .menu-container{
            ul{
                margin: 0;
                padding: 0;
                li{
                    list-style: none;
                    a{
                        text-decoration: none;
                    }
                }

            }
        }
        p{
            margin: 0;
        }
        a{
            color: $primary-color;
            text-decoration: underline;
            &:hover{
                color: $secondary-color;
                text-decoration: none;
            }
        }
    }
    .aligned{
        text-align: center;
        &.left-align{


            .logo-left{
                width :50%;
                float: left;
                .footer-nvm svg{
                    width :40px;
                    margin: 20px 0;
                }
                .footer-funda svg{
                    width :80px;
                    margin: 20px 0;
                }
            }
            .logo-right{
                width :50%;
                float: left;
                .footer-nwwi svg{
                    width :150px;
                    margin: 20px 0;
                }
                .footer-nrvg svg{                    
                    width :150px;
                    margin: 20px 0;
                }
            }
        }
        a{
            &:hover{
                color: $secondary-color;
            }
        }
        img{
            display :block;
            margin: 0 auto;
            margin-top :30px;
        }
        .funda{
            width :80px;
        }
        .nvm{
            width :40px;
        }
    }

}

.bottommenu{
    margin-top : 30px;
    ul{
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            padding-left: 30px;
            &:first-of-type{
                padding-left: 0px;
                padding-right:30px;
                border-right :1px solid $primary-color;
            }
            a{
                color: $primary-color;
            }
        }
    }
}
.vm-logo{
    text-align: center;
    padding: 30px 0;
}
.footer-logo{
    width: 100px;
    &.logo-img{
        width: 150px;
        &.goednieuws-logo{
            padding-right: 15px;
            margin-right: 15px;
            border-right: 1px solid #ddd;
        }
    }
}

@media (min-width: 320px) {
    .footer{
        &:before{
            display: none;
        }
        h4{
            margin-top :60px;
        }
    }
}
@media (min-width: 780px) {
    .footer{
        &:before{
            display: block;
        }
        h4{
            margin-top: 0;
        }
    }
}
@media (min-width: 992px) {
    .footer{
        &:before{
            display: block;
        }
    }
}

@-webkit-keyframes appear {
  0%   { bottom: -400px; }
  100% { bottom: 0px; }
}
@-moz-keyframes appear {
    0%   { bottom: -400px; }
    100% { bottom: 0px; }
}
@-o-keyframes appear {
    0%   { bottom: -400px; }
    100% { bottom: 0px; }
}
@keyframes appear {
    0%   { bottom: -400px; }
    100% { bottom: 0px; }
}

/*bottom popup video*/
.video-popup{
    position: fixed;
    display: inline-block;
    right:0;
    padding: 30px;
    padding-bottom: 90px;
    background: $primary-color;
    color: $white;
    z-index: 1000;
    box-shadow: 0 0 30px rgba(0,0,0,0.25);
    -webkit-animation: appear 1s forwards; /* Safari 4+ */
    -moz-animation:    appear 1s forwards; /* Fx 5+ */
    -o-animation:      appear 1s forwards; /* Opera 12+ */
    animation:         appear 1s forwards; /* IE 10+, Fx 29+ */
    -webkit-animation-delay: 3s; /* Chrome, Safari, Opera */
    animation-delay: 3s;
    &.active{
        display: none;
    }
    .video-popup_close{
        position: absolute;
        top: 10px;
        right: 10px;
        &:hover{
            cursor: pointer;
        }
        i{
            color: $white;
        }
    }
}
.extra-contentblock{
    background-color: #e6fde4;
    border-radius: 10px;
    padding: 30px 0;
}
