.faq{
    ul{
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
        padding: 30px;
        padding-right: 90px;
        h2{
            font-size: 1em;
        }
        &.q{
            background: $primary-background-color-light;
            cursor: pointer;
            margin-top: 30px;
        }
        &.a{
            background: #fff;
            display: none;
            color: $primary-color;
        }
        .question{
            position: relative;
            background-color: $primary-background-color;
            color: white;
            display: inline-block;
            padding: 5px 30px;
            top: -30px;
            margin-left: -30px;
        }
        .question-content{
            position: relative;
            top: -30px;
            background-color: white;
            padding: 15px;
            padding-right: 45px;
            display: block;
            @include all();
            &:after{
                position: absolute;
                background-color: $primary-background-color-25;
                bottom: -30px;
                left: 0;
                right: 0;
                height: 30px;
                content: '';
            }
            &:hover{
                background-color: $primary-background-color;
                color: white;
                transform: scale(1.02);
                box-shadow: 0 0 30px rgba(0,0,0,0.5);
                @include all();
            }
        }
        .answer{
            position: relative;
            background-color: $secondary-background-color;
            color: white;
            display: inline-block;
            padding: 5px 30px;
            top: -30px;
            margin-left: -30px;
        }
        .gold-line{
            background-color: $secondary-background-color;
            position: absolute;
            height: 4px;
            width: 50px;
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
        }
    }
}
.breadcrumb-nav{
    ol{
        li{
            padding-right: 0;
        }
    }
}

.rotate {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
