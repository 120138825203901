.usp {
	text-align: center;
	color: white;

	.title-line {
		position: absolute;
		display: block;
		left: 50%;
		transform: translateX(-50%);
		bottom: 30px;
		width: 100px;
		height: 4px;
		z-index: 3;

		&.gold {
			background-color: $secondary-background-color;
		}
	}

	&.blue-back {
		background-color: $primary-background-color;
	}

	.no {
		font-family: 'ITC Avant Garde Gothic LT';
		font-size: 0.6em;
		position: absolute;
		top: 15px;
		left: 15px;
	}

}

.usp-title {
	margin-top: -60px;

	h2 {
		font-family: 'ITC Avant Garde Gothic LT';
		text-transform: uppercase;
	}

	span {
		font-family: 'ITC Avant Garde Gothic LT';

	}

	p {
		font-size: 2em;
	}
}

.usp .col-6.usp-over {
	height: 280px;
	padding: 0;
	transform: scale(1);
	@include all();

	&:hover {
		transform: scale(1.05);
		@include all();
		z-index: 2;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.7);
		border-radius: 10px;
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		position: absolute;
		font-size: 3em;
		color: white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	a {
		position: relative;
		display: block;
		height: 100%;
		width: 100%;
		color: white;
		padding-top: 200px;
		@include all();

		&:hover {
			z-index: 2;
			@include all();
		}
	}

	&:nth-of-type(2) {
		background-color: #1b2353;

		&:before {
			content: "\f4c6";
		}

		&:hover {
			background-image: url("../../images/kennismaking-small.jpg");
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(3) {
		background-color: #0f1437;

		&:before {
			content: "\f0d6";
		}

		&:hover {
			background-image: url("../../images/Prijs-bepalen-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(4) {
		background-color: #33418b;

		&:before {
			content: "\f083";
		}

		&:hover {
			background-image: url("../../images/Voorbereiding-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(5) {
		background-color: #3646a6;

		&:before {
			content: "\f093";
		}

		&:hover {
			background-image: url("../../images/goednieuws-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(6) {
		background-color: #303c70;

		&:before {
			content: "\f015";
		}

		&:hover {
			background-image: url("../../images/bezichtigingen-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(7) {
		background-color: #272e4c;

		&:before {
			content: "\f0e3";
		}

		&:hover {
			background-image: url("../../images/veiling-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(8) {
		background-color: #1b2353;

		&:before {
			content: "\f164";
		}

		&:hover {
			background-image: url("../../images/bestebod-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(9) {
		background-color: #101644;

		&:before {
			content: "\f305";
		}

		&:hover {
			background-image: url("../../images/Koopovereenkomst-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}

	&:nth-of-type(10) {
		background-color: transparent;

		&:before {
			content: "\f0b1";
		}

		&:hover {
			background-image: url("../../images/Notaris-small.jpg");
			background-size: 100% auto;
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9)
		}
	}
}


.usp-title-wrapper {
	position: relative;
	width: 100%;
	z-index: 2;
	background-color: white;
	color: $primary-color;
	text-align: center;
	padding-bottom: 60px;

}

.usp-last {
	position: relative;
	width: 100%;
	margin-bottom: 120px;

	&:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: $primary-background-color-25;
		width: 50%;
		content: '';
	}

	.col-12 {
		color: white;

		.usp-result {
			position: relative;

			.img-woman {
				position: absolute;
				bottom: 0;
				right: 60px;
			}

			.usp-txt {
				text-transform: uppercase;
				position: absolute;
				left: 60px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 2em;
				text-shadow: 1px 1px 3px #000;
			}
		}
	}
}

/* content buttons usp */
.btn.kennis {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\f4c6";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.prijs {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F0D6";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.aanbieding {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F083";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.funda {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F093";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.huis {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F015";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.veiling {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F0E3";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.bod {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F164";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.koop {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F305";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

.btn.notaris {
	position: relative;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	color: white;
	padding: 0;
	padding-left: 60px;
	padding-right: 40px;
	line-height: 50px;

	&:after {
		content: "\F0B1";
		background-color: #20205c;
		left: 0px;
		text-align: center;
		width: 50px;
		position: absolute;
	}
}

/* responsive */
@media (min-width: 320px) {
	.usp {
		font-size: 1em;

		.usp-title-wrapper {
			.usp-title-background {
				background-color: #fff;
				position: static;
				margin-left: 0px;
			}

			.usp-title {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				margin-top: 0px;

				h2 {
					font-size: 1em;
				}

				p {
					font-size: 1em;
				}
			}
		}
	}
	.usp .col-6.usp-over {
		height: 200px;
	}
	.usp .col-6.usp-over a {
		padding-top: 150px;
	}
	.usp-last {
		.col-12 {
			.usp-result {
				.usp-txt {
					font-size: 1em;

					h2 {
						font-size: 1em;
					}
				}

				.img-woman {
					position: absolute;
					bottom: 0;
					height: 200px;
					right: 15px;
				}
			}

		}

		.house {
			height: 200px;
		}
	}
	/* content buttons */
	.btn {
		&.kennis,
		&.prijs,
		&.aanbieding,
		&.funda,
		&.huis,
		&.veiling,
		&.bod,
		&.koop,
		&.notaris {
			float: none;
			margin-bottom: 15px;
			width: 100%;
		}
	}
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
	.usp {
		font-size: 1.3em;
	}
	.usp {
		.usp-title-wrapper {
			padding-top: 90px;

			&:after {
				position: absolute;
				width: 50%;
				right: -50%;
				top: 0;
				bottom: 0;
				content: '';
				background-color: $secondary-background-color;
			}

			.usp-title {
				position: static;
				top: 0;
				left: 0;
				transform: translate(0%, 0%);
				margin-top: -60px;

				h2 {
					font-size: 2rem;
				}

				p {
					font-size: 2em;
				}
			}
		}
	}
	.usp .col-6.usp-over {
		height: 280px;
	}
	.usp .col-6.usp-over a {
		padding-top: 200px;
	}
	.usp-last {
		.col-12 {
			.usp-result {
				.usp-txt {
					font-size: 2em;
				}

				h2 {
					font-size: 2em;
				}

				.img-woman {
					position: absolute;
					bottom: 0;
					height: 400px;
					right: 60px;
				}
			}
		}

		.house {
			height: auto;
		}
	}

}

@media (min-width: 1200px) {
	.usp-last {
		.col-12 {
			.usp-result {
				.usp-txt {
					font-size: 2em;
				}

				h2 {
					font-size: 2em;
				}

				.img-woman {
					position: absolute;
					bottom: 0;
					height: 400px;
					right: 60px;
				}
			}
		}

		.house {
			height: auto;
		}
	}
	/* content buttons */
	.btn {
		&.kennis,
		&.prijs,
		&.aanbieding,
		&.funda,
		&.huis,
		&.veiling,
		&.bod,
		&.koop,
		&.notaris {
			float: inherit;
			margin-bottom: 15px;
			width: auto;
		}
	}
}

@media (min-width: 1400px) {
	.usp-last {
		.col-12 {
			.usp-result {
				.img-woman {
					position: absolute;
					bottom: 0;
					height: 500px;
					right: 60px;
				}
			}
		}
	}
}

@media (min-width: 1600px) {

}
