body {
	font-family: 'Slabo 27px', serif;
	background-color: transparent;
	color: $primary-color;
	overflow-x: hidden;
}

h2, h3, h4, h5, h6 {
	font-family: 'Slabo 27px', serif;
}

.main-content, .home-content {
	text-align: center;

	h1 {
		position: relative;
		font-family: 'Slabo 27px', serif;
		margin-bottom: 60px;
		font-size: 1.8em;
		font-weight: bold;

		&:after {
			position: absolute;
			bottom: -30px;
			width: 75px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			border-bottom: 4px solid $secondary-color;
		}
	}

	h2 {
		position: relative;
		font-family: 'Slabo 27px', serif;
		margin-bottom: 60px;
		font-size: 1.4em;
		font-weight: bold;

		&:after {
			position: absolute;
			bottom: -30px;
			width: 75px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			border-bottom: 4px solid $secondary-color;
		}
	}

	p {
		margin-bottom: 30px;
	}
}

.home-content {
	margin-top: 60px;
}

.section.inhoud {
	h1 {
		text-align: center;
		position: relative;
		font-family: 'Slabo 27px', serif;
		margin-bottom: 60px;
		font-size: 1.8em;
		font-weight: bold;

		&:after {
			position: absolute;
			bottom: -30px;
			width: 75px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			border-bottom: 4px solid $secondary-color;
		}
	}

	h2 {
		position: relative;
		font-family: 'Slabo 27px', serif;
		margin: 30px 0;
		font-size: 1.4em;
		font-weight: bold;

		&:after {
			position: absolute;
			bottom: -15px;
			width: 50px;
			left: 0;
			content: '';
			border-bottom: 2px solid $secondary-color;
		}
	}

	h3 {
		position: relative;
		font-family: 'Slabo 27px', serif;
		margin: 30px 0;
		font-size: 1.2em;

		&:after {
			position: absolute;
			bottom: -15px;
			width: 25px;
			left: 0;
			content: '';
			border-bottom: 2px solid $primary-color;
		}
	}
}

.no-padding {
	padding: 0;
}

.main-content {
	position: relative;

	.main-content-img {
		position: relative;

		&:after {
			background-color: $primary-background-color-75;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			content: '';
			z-index: 1;
		}

		&:before {
			background-color: $primary-background-color-25;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -50%;
			width: 100%;
			content: '';
			z-index: -1;
		}
	}

	.main-content-content {
		color: white;
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.pulse {
	-webkit-animation: pulse 2s infinite;
	-moz-animation: pulse 2s infinite;
	-o-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	animation-timing-function: ease-in-out;
}


/* responsive */
@media (min-width: 320px) {
	h1.page-title {
		display: block;
		text-align: center;
		padding-bottom: 15px;
		font-size: 2em;

		&:after {
			display: block;
			width: 60px;
			background-color: $secondary-background-color;
			height: 4px;
			margin: 0 auto;
			margin-top: 15px;
			content: '';
			margin-bottom: 15px;
		}
	}
	.section.inhoud {
		padding: 0 15px;
	}
	.main-content {
		position: relative;

		.main-content-img {
			height: 400px;

			img {
				height: 100%;
			}
		}
	}
}

@media (min-width: 992px) {
	h1.page-title {
		display: none;
	}
	.main-content {
		position: relative;

		.main-content-img {
			height: auto;
		}
	}
}

@media (min-width: 1600px) {
	.container {
		min-width: 1400px;
	}
}
