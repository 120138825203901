.btn{
    position: relative;
    border-radius: 0px;
    border: none;
    padding: 0;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    @media (min-width: 320px) {
        font-size: 18px;
    }
    @media (min-width: 768px) {
        font-size: 12px;
    }
    @media (min-width: 992px) {
        font-size: 14px;
    }
    @media (min-width: 1200px) {
        font-size: 18px;
    }
    i{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover{
        color: white;
    }
    &:focus{
        color: white;
    }
    &:active{
        color: white;
    }
    &:before{
        position: absolute;
        content: '';
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $primary-background-color;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
    &:focus:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
    &:focus:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
    &.btn-primary{
        background-color: $primary-background-color;
        a{
            color: #fff;
            display: block;
            padding: 20px 50px;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &.btn-secondary{
        background-color: $secondary-background-color;
        a{
            color: #fff;
            display: block;
            padding: 20px 50px;
            &:hover{
                text-decoration: none;
            }
        }
        &.logout{
            background-color: $red-background-color;
            color: white !important;
            text-decoration: none !important;
        }
        .logo-small-white{
            width: 24px;
            height: 24px;
            margin-left: -30px;
            margin-right: 30px;
        }
    }


}
.btn.btn-secondary, .btn.btn-primary{
    padding: 20px 50px;
}

.login .btn{
    &:before{
        content: '';
    }
}
.swiper-pagination, .swiper-hero-pagination{
	position: absolute;
	bottom: 0px;
	left :50%;
	transform: translateX(-50%);
    z-index: 1;
}
.swiper-pagination-bullet{
    display: inline-block;
	width: 60px;
	height: 5px;
	border-radius: 0px;
	background-color: $primary-background-color-50;
	margin-right: 10px;
}
.swiper-pagination-bullet-active{
	background-color: $primary-background-color;
}
/*soon*/
.swiper-pagination-2{
	position: absolute;
	bottom: 0px;
	left :50%;
	transform: translateX(-50%);
    z-index: 1;
}
.swiper-pagination-2 .swiper-pagination-bullet{
    display: inline-block;
	width: 60px;
	height: 5px;
	border-radius: 0px;
	background-color: $secondary-background-color-50;
	margin-right: 10px;
}
.swiper-pagination-2 .swiper-pagination-bullet-active{
	background-color: $secondary-background-color;
}
/*
.soon{
    .swiper-pagination-bullet{
    	width: 60px;
    	height: 5px;
    	border-radius: 0px;
    	background-color: $secondary-background-color-50;
    	margin-right: 10px;
    }
    .swiper-pagination-bullet-active{
    	background-color: $secondary-background-color;
    }
}*/
.swiper-button-next {
    position: absolute;
    background-image: none;
    top: 40%;
    right: 15px;
    width: 100px;
    text-align: center;
    height: auto;
    @include all();
    &:focus{
        outline: none;
    }
    &:hover{
        right: 0px;
        @include all();
    }
    i{
        &.fa-chevron-right:before{
            color: white;
            font-size: 4em;
            text-shadow: 0 0 15px rgba(0,0,0,0.25);
        }

    }
}
.swiper-button-next-2{
    position: absolute;
    background-image: none;
    top: 40%;
    right: 15px;
    width: 100px;
    text-align: center;
    height: auto;
    @include all();
    &:focus{
        outline: none;
    }
    &:hover{
        right: 0px;
        @include all();
    }
    i{
        &.fa-chevron-right:before{
            color: white;
            font-size: 4em;
            text-shadow: 0 0 15px rgba(0,0,0,0.25);
        }

    }
}

@-webkit-keyframes pulse {
  0%   {
      background-color: $secondary-background-color;
      box-shadow: 0 0 0px rgba(185,130,40,0);
      text-shadow: 0 0 0px rgba(255,255,255,0);
  }
  50% {
      background-color: $secondary-background-color;
       box-shadow: 0 0 50px rgba(185,130,40,1);
       text-shadow: 0 0 5px rgba(255,255,255,1);
  }
  100%   {
      background-color: $secondary-background-color;
       box-shadow: 0 0 0px rgba(185,130,40,0);
       text-shadow: 0 0 0px rgba(255,255,255,0);
  }
}
@-moz-keyframes pulse {
    0%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
    50% {
        background-color: $secondary-background-color;
         box-shadow: 0 0 50px rgba(185,130,40,1);
         text-shadow: 0 0 5px rgba(255,255,255,1);
    }
    100%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
}
@-o-keyframes pulse {
    0%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
    50% {
        background-color: $secondary-background-color;
         box-shadow: 0 0 50px rgba(185,130,40,1);
         text-shadow: 0 0 5px rgba(255,255,255,1);
    }
    100%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
}
@keyframes pulse {
    0%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
    50% {
        background-color: $secondary-background-color;
         box-shadow: 0 0 50px rgba(185,130,40,1);
         text-shadow: 0 0 5px rgba(255,255,255,1);
    }
    100%   {
        background-color: $secondary-background-color;
         box-shadow: 0 0 0px rgba(185,130,40,0);
         text-shadow: 0 0 0px rgba(255,255,255,0);
    }
}
.btn.btn-outline-secondary.docs-datepicker-trigger:hover{
    color: #6c757d;
}
