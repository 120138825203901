// Frontend
$primary-color: rgba(32,32,92,1) !default;
$primary-background-color: rgba(32,32,92,1) !default;
$primary-background-color-75: rgba(32,32,92,.75) !default;
$primary-background-color-50: rgba(32,32,92,.5) !default;
$primary-background-color-25: rgba(32,32,92,.25) !default;
$primary-background-color-15: rgba(32,32,92,.05) !default;
$primary-background-color-light: #dedee7 !default;
$primary-background-color-dark: #0f1437 !default;


$secondary-color: rgba(185,130,40,1) !default;
$secondary-background-color: rgba(185,130,40,1) !default;
$secondary-background-color-75: rgba(185,130,40,.75) !default;
$secondary-background-color-50: rgba(185,130,40,.5) !default;
$secondary-background-color-25: rgba(185,130,40,.25) !default;
$secondary-background-color-light: #f4ecdf !default;

$red-color: rgba(196,32,0,1) !default;
$red-background-color: rgba(196,32,0,1) !default;

$text-color: #20215c !default;
$title-color: darken($text-color, 5%) !default;
$h1-color: #20215c !default;

// Cookie
$mainCookieBG: #20205c !default;
$cookieLinkColor: #fff !default;
$cookieSlideActiveColor: darken($mainCookieBG, 30%) !default;
$cookieCloseIconColorOnHover: #b98228 !default;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%) !default;

$cookieBtnBG: #b98228 !default;

// Beckend
$color-brand: #009530;

$color-green: $color-brand;
$color-natural: #f1eee5;

$color-text-light: #a3a8a3;
$color-text-lighter: #dfe4df;
$color-text-natural: #a19e95;

$color-primary: #1ab23a;
$color-secondary: #d76e96;

$color-warning: #EEEE00;

$color-error: #A94442;
$color-error-light: #e55162;

$color-input-focus: #8dc6f1;
