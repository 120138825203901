.btn-close-modal{
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: $red-background-color;
    padding: 2px 10px;
    color: white;
    border: none;
    padding: 0;
    line-height: 40px;
    width: 40px;
    height: 40px;
    i{
        position: static;
        color: white;

    }
    &:hover{
        cursor: pointer;
    }
}


@media (min-width: 320px) {
    .frontend-login-page{
        margin-top: 30px;
        .modal-hr{
            display: block;
        }
    }
    #login-modal{
        background-color: $primary-background-color-75;
        .modal-content{
            position: absolute;
            top: 5%;
            left :50%;
            transform: translate(-50% , 0%);
            width: 90%;
            padding: 30px;
            border-radius: 0px;
            box-shadow: 0 0 50px rgba(0,0,0,0.5);
            .modal-header{
                border: none;
            }
            .modal-hr{
                display: block;
            }
        }

    }
}
@media (min-width: 992px) {
    .frontend-login-page{
        margin-top: 100px;
        .modal-hr{
            display: none;
        }
    }
    #login-modal{
        background-color: $primary-background-color-75;
        .modal-content{
            position: absolute;
            top: 50%;
            left :50%;
            transform: translate(-50% , -50%);
            width: 60%;
            padding: 30px;
            border-radius: 0px;
            box-shadow: 0 0 50px rgba(0,0,0,0.5);
            .modal-header{
                border: none;
            }
            .modal-hr{
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
    #login-modal{
        .modal-content{
            width: 50%;
            .modal-header{
                border: none;
            }
            .modal-hr{
                display: none;
            }
        }
    }
}
