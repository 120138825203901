.calendar-top{
    background: transparent url("../../images/calendar-top.png") repeat-x top left;
     height: 77px;
     width: 100%;
   }
   .calendar-body ul{
     margin: 0;
     padding: 0;
   }
   .calendar-body li{
     list-style: none;
     padding: 20px 0;
     border-bottom :2px dashed #20205c;
     float: left;
     width: 100%;
   }
   .calendar-body li p{
     line-height: 21px;
   }
   .calendar-body li .day{
     background-color: #20205c;
     display: inline-block;
     float: left;
     text-align: center;
     width :60px;
     height: 60px;
     padding-top :5px;
     color: white;
     font-weight: bold;
     .day-name{
         font-weight: normal;
         display: block;
         font-size: 0.6em;
         letter-spacing: 1px;
     }
   }
   .calendar-body li .day.active{
     background-color: #b98228;
   }
   .calendar-body li .day.start{
     background-color: #17c834;
   }
   .calendar-body li .day.stop{
     background-color: #17c834;
   }
   .calendar-body li .check{
     background: transparent url(../../images/check.png) no-repeat top left;
     display: block;
     margin-top: 20px;
     float: right;
     width :36px;
     height: 38px;
   }
   .calendar-body li .check.checked{
    background: transparent url(../../images/checked.png) no-repeat top left;
   }
   .calendar-body li .logos.first{
     float: left;
     width :220px;
     height: 60px;
   }
   .calendar-body li .logos{
     float: left;
     width : 160px;
     height: 60px;
   }
   .calendar-body li .logos.single{
     float: left;
     width : 80px;
     height: 60px;
   }
   .calendar-body li .logos img{
     float: left;
     margin-left: 10px;
   }
   .calendar-body li .calendar-content{
     font-weight: bold;
     display: block;
     float: left;
     width :auto;
     height: 50px;
     padding-left: 10px;
     &.empty{
         height: 0px;
         padding: 0;
     }
   }
   .calendar-bottom{
       // padding: 30px 0;
       padding-bottom: 30px;
       float: left;
       width: 100%;
   }
/* responsive */
@media (min-width: 320px) {
    .calendar-body li .calendar-content{
        width: 100%;
        padding: 30px 0;
        &.empty{
            height: 0px;
            padding: 0;
            float: left;
            display: none;
        }
    }

}
/* responsive */
@media (min-width: 768px) {
    .calendar-body li .calendar-content{
      width: calc(100% - 316px) !important;
      font-weight: bold;
      display: block;
      float: left;
      width :auto;
      height: 50px;
      padding: 0;
      padding-left: 10px;
    }

}
