section.hero{
    position: relative;
    width: 100%;
    height: 85vh;
    .hero-wrapper{
        position: relative;
        height: 100%;
        width :100%;
        .hero-visual{
            position: absolute;
            right: 0;
            top: 0;
            overflow: hidden;
            width: 68%;
            height: 85vh;
        }
        &.hero-page{
            .visual{
                height: 100%;
                .swiper-hero{
                    height: 100%;
                    .visual__image{
                        height: 100%;
                        width :100%;
                        img{
                            height: 100%;
                            width :100%;
                        }
                    }
                }
            }
        }
    }


    .hero-content{
        position: absolute;

        z-index: 2;
        .slider-content-left{
            position: relative;
            padding: 60px;
            padding-left: 0;
            background-color: #fff;

            &:after{
                position: absolute;
                background-color: $primary-background-color-25;
                bottom: -30px;
                left: 0;
                right: 0;
                height: 30px;
                width :100%;
                content: '';
            }
            h1{
                font-family: 'ITC Avant Garde Gothic LT';
                text-transform: uppercase;
                font-weight: bold;
                font-size: 2em;
            }
            h2{
                font-family: 'ITC Avant Garde Gothic LT';
                text-transform: uppercase;
                font-weight: bold;
            }
            h3{
                font-size: 2em;
                font-weight: bold;
                text-transform: uppercase;
            }
            .intro{
                font-size: 2.5em;
                font-weight: bold;
                line-height: 1em;
            }
            .title-line{
                display: block;
                height: 4px;
                width: 100px;
                background-color: $primary-background-color;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 3;
                right: -75px;
            }
            a.btn{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width :100%;
                &.pulse{
                    -webkit-animation: pulse 2s infinite;
                    -moz-animation:    pulse 2s infinite;
                    -o-animation:      pulse 2s infinite;
                    animation:         pulse 2s infinite;
                    animation-timing-function: ease-in-out;
                    }
                }

        }
        .slider-content{
            position: absolute;
            top: 50%;
            left: 200px;
            transform: translateY(-50%);
            color: white;
            text-shadow: 1px 1px 3px rgba(0,0,0,1);
            font-size: 2.5em;
            ul{
                margin: 0;
                padding: 0;
                li{
                    position: relative;
                    list-style: none;
                    &:before{
                        font-family: 'Font Awesome 5 Pro';
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: inherit;
                        position: absolute;
                        content: "\f00c";
                        font-size: 1em;
                        color: white;
                        top: 50%;
                        left: -60px;
                        transform: translateY(-50%);
                        z-index: 1;
                    }
                }
            }
        }
    }
}
.visual__image{
    position: relative;
    &:after{
        position: absolute;
        top: 0;
        left :0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-background-color-75;
        content: '';
        z-index: 2;
    }
    .visual__image-img{
        object-fit: cover;
    }
}
.visual-page{
    .slider-content-left{
            h1{
                font-family: 'ITC Avant Garde Gothic LT';
                margin-bottom: 60px;
                font-size: 2em;
            }
    }
}
/* responsive */
@media (min-width: 320px) {
    section.hero {
        height: 550px;
        .hero-wrapper{
            height: 550px;
            .hero-visual{
                width :100%;
                height: 550px;
                img{
                    height: 550px;
                }
            }
        }
        .hero-content{
            display: none;
            top: 0;
            transform: translateY(0);
            .slider-content-left{
                .title-line{
                    display: none;
                }
            }
        }
    }

    .swiper-pagination-bullet{
        width: 20px;
    }
    .slider-content-mobile{
        display: block;
        text-align: center;
        position: absolute;
        top: 100px;
        color: white;
        z-index: 2;
        li{
            list-style: none;
            float: left;
            text-align: left;
            position: relative;
            margin-right: 50px;
            &:before{
                font-family: 'Font Awesome 5 Pro';
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                position: absolute;
                content: "\f00c";
                font-size: 1em;
                color: white;
                top: 50%;
                left: -20px;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
    }
    .slider-content-mobile-bottom{
        position: absolute;
        width :100%;
        bottom: 0px;
        color: $primary-color;
        background-color: white;
        z-index: 2;
        .slider-content-mobile-bottom-block{
            display: inline-block;
            padding: 15px;
        }
        .btn.btn-secondary.pulse{
            width :100%
        }
    }
    .soon .swiper-pagination-bullet{
        width: 20px;
    }
}
@media (min-width: 768px) {
    section.hero {
            height: 55vh;
            .hero-wrapper{
                height: 55vh;
                .hero-visual{
                    height: 55vh;
                    img{
                        width: 100%;
                    }
                }
            }
        }
}
@media (min-width: 992px) {
    section.hero {
            height: 85vh;
            &.visual-page{
                text-transform: uppercase;
                height: 60vh;
                .hero-wrapper{
                    .hero-visual{
                        height: 60vh;
                    }
                }
                .hero-content{
                    display: block;
                    top: 75%;
                    width: 100%;
                    transform: translateY(-50%);
                    .slider-content-left{
                        padding-bottom: 90px;
                    }
                }
            }
        .hero-wrapper{
            .hero-visual{
                width : 68%;
                height: 85vh;
                img{
                    height: 85vh;
                }
            }
        }
        .hero-content{
            display: block;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);

            .slider-content{
                font-size: 1.2em;
            }

            .slider-content-left{
                .intro{
                    font-size: 1.8em;
                }
                .title-line{
                    display: block;
                }
            }
        }
    }
    .soon .swiper-pagination-bullet,
    .swiper-pagination-bullet{
        width: 60px;
    }
    .slider-content-mobile, .slider-content-mobile-bottom{
        display: none;
    }
    .visual-page{
        margin-bottom: 120px;
        .slider-content-left{
                h1{
                    font-family: 'Slabo 27px', serif;
                    margin-bottom: 60px;
                }
        }
    }
    .swiper-pagination-bullet-active{
        display: inline-block;
    }

}
@media (min-width: 1200px) {

    section.hero {
        .hero-content{
            .slider-content{
                font-size: 1.5em;
            }
            .slider-content-left{
                .intro{
                    font-size: 2.0em;
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    section.hero {
        .hero-content{
            .slider-content{
                font-size: 2.0em;
            }
            .slider-content-left{
                .intro{
                    font-size: 2.0em;
                }
            }
        }
    }
}
@media (min-width: 1600px) {
    section.hero {
        .hero-content{

            .slider-content-left{
                .intro{
                    font-size: 2.5em;
                }
            }
        }
    }
}
