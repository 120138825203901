.bg-blue{
    background-color: $primary-background-color;
    margin-bottom: 45px;

}

.top-detail{
    position: relative;
    padding-top: 30px;
    .move-down-link-wrapper{
        position: relative;
        text-align: center;
        border-top: 1px solid white;
        padding-top : 10px;
        a{
            position: relative;
            display: inline-block;
            padding: 5px 15px;
            top:-28px;
            z-index: 1;
            color: white;
            background-color: $primary-background-color;
            border: 1px solid white;
            &:hover{
                background-color: $secondary-background-color;
            }
        }

    }
    table{
        th{
            border: none;
            color: white;
            padding: 0.5rem;
            &.table-title{
                text-align: center;
            }
        }
        td{
            border: none;
            color: white;
            padding: 0.5rem;
            &.bid-total{
                text-align: center;
            }
            &.date{
                background-color: #fff;
                color: $primary-color;
                font-size: 14px;
                width: 60px;
                border-bottom: 3px solid $primary-color;
                span{
                    display: block;
                }
            }
        }
    }
    .top-detail-right table{
        text-align: left;
        tr{
            border-bottom: 1px solid rgba(255,255,255,0.25);
            &.last-tr{
                border: none;
            }
        }
    }
    .top-detail-right{
        padding-top: 60px;
        .counter-wrapper{
            position: absolute;
            top: 0px;
            width: 100%;
            background-color: white;
            text-align: center;
            padding: 10px;
            font-size: 1.5em;
            font-family: 'ITC Avant Garde Gothic LT';
         }
    }

}
.swiper-button-prev-3{
    display: none;
}
.swiper-button-next-3 {
    background-image: none;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 100px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    font-size: 3em;
    color: white;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
    &:focus{
        outline: none;
    }
}

section.object-content{
    .openhouse,
    .view-by-appointment {
        background-color: $secondary-background-color-light;;
        padding: 15px;
        margin-bottom :45px;
    }
    .table-title{
        border-top: none;
    }
}
.index-button{
    margin-bottom : 60px;
}

.slick-lightbox-slick {
    .slick-prev:hover, .slick-next:hover{
        cursor: pointer;
    }
    .slick-prev::before {
        content: "←";
    }

    .slick-next::before {
        content: "→";
    }
    .slick-prev:before, .slick-next:before {
        display: inline-block;
        font-family: 'slick';
        font-size: 20px;
        width: 30px;
        height: 30px;
        background-color: white;
        line-height: 30px;
        border-radius: 15px;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    button.slick-prev{
        z-index: 2;
        position: absolute;
        background-color: transparent;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        right: auto;
        font-size: 0px;
    }
    button.slick-next{
        position: absolute;
        background-color: transparent;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        font-size: 0px;
    }
}
.index-button{
    .btn{
        i{
            left: 30px;
            right: auto;
        }
    }
}

section.detail-maps{
    position: relative;
    z-index: 2;

    .container{
        position: relative;
        height: 500px;

        .maps-wrapper{
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            height: 500px;
            &:before{
                background-color: #dedede;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -50%;
                width : 100%;
                content: '';
                z-index: -1;
            }
        }
    }
    .content-title{
        font-family: 'ITC Avant Garde Gothic LT';
        font-size: 2rem;
        text-align: center;
    }

}
.dashboard{
    .card__dashboard{
        background-color: $primary-background-color-light;
        text-align: left;
        figure{
            height: 200px;
            position: relative;
            overflow: hidden;
            height: 100%;
            img{
                position: absolute;
                top : 50%;
                transform: translateY(-50%);
            }
        }
        .bid-low, .bid-high{
            padding-top :30px;
            font-weight: bold;
            width :50%;
            float: left;
        }
        .card__info{
            text-align: left;
        }
    }
}
.table-downloads{
    tr{
        border-bottom: 1px solid #ddd;
    }
    i{
        float: left;
        margin-right: 15px;
    }
    a{
        color: $primary-color;
        &.btn.btn-secondary{
            color: white;
        }
    }
}
@media (min-width: 320px) {
    section.detail-title-bar {
        margin-top: 100px;
        position: relative;
    }
    .detail-header-image{
        text-align: center;
        .visual__image-img{
            height: auto;
            width :100%;
        }
        .countdown-wrapper{
            position: relative;
            background-color: #fff;
            overflow: hidden;
            padding: 10px;
            border-radius: 5px;
            z-index: 3;
            .card__logo{
                float: left;
                margin-right: 10px;
                img{
                    height: 32px;
                }
            }
            &.sold{
                background-color: $red-background-color;
                .card__count{
                    color: white;
                }
            }

        }
        .card__bid.blue-back{
            overflow: hidden;
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-top: -15px;
            color: white;
            background-color: $primary-color;
            padding: 15px;
            padding-top: 30px;
            p{
                margin: 0;
            }
            .bid-low{
                float: left;
            }
            .bid-high{
                float: right;
            }
        }
        .btn.btn-secondary{
            margin: 0 auto;
        }
        .detail-header-image-card__count{
            position: absolute;
            bottom: -100px;
            left: 50%;
            transform: translateX(-50%);


            time{
                float: left;
                font-family: 'ITC Avant Garde Gothic LT';
                font-size: 1em;
            }

        }
    }
    .top-detail{
        padding-top: 120px;
        padding-bottom: 30px;
    }
    .row.top-detail{
        margin-right: 0px;
        .top-detail-left, .top-detail-right{
            position: relative;
            width : 100%;
            float: left;
        }
        .top-detail-right{
            .btn.btn-secondary{
                position: static;
            }
            .alert.alert-warning{
                border: none;
                background-color: transparent;
                color: $red;
            }
        }

    }
    .dashboard{
        .card__dashboard{
            figure{
                height: 200px;
            }
            .bid-low, .bid-high, .card__info{
                padding: 0 30px;
            }
        }
    }
    section.detail-title-bar .content-title .title-line.gold{
        display : none;
    }
    section.detail-maps{
        position: relative;
        .container{
            position: relative;
            height: 500px;
            .maps-wrapper{
                position: absolute;
                width: 100%;
                top :100px;
                left: 0;
                right: 0;
                height: 500px;
            }
        }
    }
    .detail-photo-slider{
        .bg-light-gold{
            background-color: transparent;
            .content-title{
                text-align: center;
                margin-bottom :30px;
            }
            .slick-slide{
                height: 400px;
                img{
                    height: 400px;
                }
            }
        }
    }

    .slick-arrow{
        cursor: pointer;
        position: absolute;
        right: -100px;
        top: 50%;
        color: white;
        font-size: 3em;
        transform: translateY(-50%);
        &:hover{
            color: $secondary-color;
        }
    }
    .slick-dots{
        margin: 0 auto;
        margin-top: 30px;
        padding: 0;
        height: 5px;
        li{
            float: left;
            display: inline-block;
            list-style: none;
            width: 60px;
            height: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            &.slick-active{
                button{
                    background-color: $secondary-background-color;
                }
            }
            button {
                width: 60px;
                height: 5px;
                background-color: $secondary-background-color-25;
                text-indent: -9999px;
                border: none;
            }
        }
    }
    .slider.multiple-items{
        position: relative;
        width :100%;
        z-index: 2;
        i{
            position: absolute;
            top: 50%;
            z-index: 3;
            &.fa-chevron-left{
                left: 15px;
            }
            &.fa-chevron-right{
                right: 15px;
            }
        }
    }
    section.detail-maps .content-title{
        position: absolute;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
    }

    .top-detail-right table{
        text-align: left;
        tr{
            border-bottom: 1px solid rgba(255,255,255,0.25);
            &.last-tr{
                border: none;
            }
            td{
                padding-left: 30px;
                &.date{
                    padding: 0.75rem;
                }
            }
        }
    }

}
@media (min-width: 992px) {
    section.detail-title-bar {
        margin-top: 200px;
        position: relative;
    }
    .top-detail{
        padding-top :30px;
        padding-bottom: 0px;
    }
    .detail-header-image{
        text-align: left;
        .visual__image-img{
            height: 320px;
        }
        .btn.btn-secondary{
            position: absolute;
            bottom: 15px;
            right: 15px;
            width: calc(100% - 30px);
            margin: 15px;
        }
    }
    .dashboard{
        .card__dashboard{
            figure{
                height: 300px;
                margin: 0;
            }
            .bid-low, .bid-high, .card__info{
                padding: 30px 0;
            }
        }
    }
    section.detail-title-bar .content-title .title-line.gold{
        display :block;
    }

    section.detail-photo-slider{
        position: relative;
        margin-bottom :60px;
        &:before{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            background-color: transparent;
            content: '';
        }




        .slider-detail-wrapper{
            height: 500px;
            .slider{
                width :1000px;
                float: right;
                img{
                    height: 500px;
                    position: relative
                }
                .fa-chevron-left{
                    display: none !important;
                }
                .slick-slide{
                    div{
                        a{
                            position: relative;
                            @include all();
                            text-decoration: none;
                            text-align: center;
                            line-height: 500px;
                            &:hover:after{
                                font-family: 'Font Awesome 5 Pro';
                                font-style: normal;
                                font-weight: normal;
                                text-decoration: inherit;
                                color: white;
                                z-index: 1;
                                position: absolute;
                                font-size: 2em;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                background-color: $primary-background-color-75;
                                content: "\f00e";
                                @include all();
                            }
                        }
                    }
                }

            }
            .slick-arrow{
                cursor: pointer;
                position: absolute;
                right: -100px;
                top: 50%;
                color: white;
                font-size: 3em;
                transform: translateY(-50%);
                &:hover{
                    color: $secondary-color;
                }
            }
            .slick-dots{
                margin: 0;
                margin-top: 30px;
                padding: 0;
                li{
                    display: inline-block;
                    list-style: none;
                    width: 60px;
                    height: 5px;
                    margin-right: 10px;
                    &.slick-active{
                        button{
                            background-color: $secondary-background-color;
                        }
                    }
                    button {
                        width: 60px;
                        height: 5px;
                        background-color: $secondary-background-color-25;
                        text-indent: -9999px;
                        border: none;
                    }
                }
            }
        }
    }

    section.detail-maps{
        position: relative;
        .container{
            position: relative;
            height: 500px;
            .maps-wrapper{
                position: absolute;
                width: 100%;
                top :0;
                left: 0;
                right: 0;
                height: 500px;
            }
        }
    }

    .detail-header-image .detail-header-image-card__count{
        bottom: 0px;
    }
    .detail-header-image .detail-header-image-card__count time{
        font-size: 1.2em;
    }
    .detail-header-image{
        .visual__image-img{
            width :100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .top-detail-right table{
        text-align: left;
        tr{
            border-bottom: 1px solid rgba(255,255,255,0.25);
            &.last-tr{
                border: none;
            }
        }
    }
    section.detail-maps{
        .content-title{
            top: -100px;
        }
    }

}
@media (min-width: 1200px) {
    .detail-header-image .detail-header-image-card__count time{
        font-size: 1.5em;
    }
    section.detail-title-bar .content-title .title-line.gold{
        display :block;
    }
    .content-title{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 60px;
        width: 240px;
        background-color: white;
        padding: 30px;
        z-index: 3;
        &:after{
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            content: '';
            background-color: $secondary-background-color-25;
            height: 30px;
        }
        &.gold{
            color: $secondary-color;
        }
        .title-line{
            position: absolute;
            display: block;
            height: 4px;
            width: 60px;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            &.blue{
                background-color: $primary-background-color;
            }

        }

    }
    section.detail-photo-slider{
        .bg-light-gold{
            background-color: $secondary-background-color-light;
            height: 500px;
            .slick-slide{
                height: auto;
                img{
                    height: 500px;
                }
            }
        }
    }


    section.detail-photo-slider{
        position: relative;
        margin-bottom :120px;
        &:before{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            background-color: $secondary-background-color-25;
            content: '';
        }
    }
    section.detail-maps{
        .content-title{
            font-family: 'ITC Avant Garde Gothic LT';
            font-size: 1em;
        	position: absolute;
        	top: 50%;
        	transform: translateY(-50%);
        	left: 60px;
            width: 240px;
        	background-color: white;
        	padding: 30px;
        	z-index: 2;
            &:after{
            	position: absolute;
            	bottom: -30px;
            	left: 0;
            	right: 0;
            	content: '';
            	background-color: $primary-background-color-25;
            	height: 30px;
            }

            .title-line{
                position: absolute;
                display: block;
                height: 4px;
                width: 60px;
                right: -30px;
                top: 50%;
                transform: translateY(-50%);
                &.blue{
                    background-color: $primary-background-color;
                }
            }
        }
    }

}
@media (min-width: 1440px) {
    section.detail-maps{
        position: relative;
        .container{
            position: relative;
            height: 500px;
            .maps-wrapper{
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                height: 500px;
            }
        }
    }
    .row.top-detail{
        margin-right: 0px;
        .top-detail-left, .top-detail-right{
            position: relative;
            width :50%;
            float: left;
        }
    }
    .detail-header-image{
        .top-detail-right{
            position: relative;
            background: $primary-background-color-dark;
            height: 500px;
            margin-top: -30px;
            padding-top: 30px;
        }
    }
}
@media (min-width: 1600px) {
    section.detail-photo-slider{
        .slider-detail-wrapper{
            height: 500px;
            .slider{
                width :1200px;
                float: right;
            }
        }
    }
}
