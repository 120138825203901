.bg-light-gold {
	background-color: $secondary-background-color-light;
}

.dashboard {
	position: relative;
	text-align: center;

	table {
		text-align: left;
		font-size: 12px;

		tfoot {
			text-align: right;
			border-top: 3px double $primary-color;

			.total {
				font-family: 'ITC Avant Garde Gothic LT';
				font-size: 1.2em;
			}
		}
	}

	.box-shadow {
		border: 1px solid #ddd;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
	}

	.btn.place-bid {
		padding-left: 60px;
		background-image: url("../../images/logo-small-white.svg");
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-position: 15px center;
	}

	h2 {
		font-size: 1.4em !important;
	}

	.bg-dark-blue {
		position: relative;
		background-color: $primary-background-color;
		height: 100%;
		color: white;
		padding: 30px;

		.bod-input {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 75%;
			transform: translate(-50%, -50%);

			#bidding_price {
				background: white url("../../images/uw-bod.svg") no-repeat 20px center;
			}

			p {
				text-align: left;
				margin: 10px 0;

				&.right {
					text-align: right;
				}
			}
		}
	}

	.kk {
		padding: 30px;
		padding-left: 0px;
	}

	input {
		font-family: 'ITC Avant Garde Gothic LT';
		color: $primary-color;
		width: 100%;
		border: none;
		border-radius: 10px;
		font-size: 2em;
		text-align: right;
		padding: 10px 30px;

	}

	.object-title {
		text-align: center;
		padding: 30px 0;

		h1 {
			font-size: 1.2em !important;
		}
	}

	.dashboard-left {
		.alert {
			border-radius: 0;
			&.alert-warning {
				position: absolute;
				bottom: -30px;
				left: 0;
				right: 0;
			}
		}
		.but-ifs {
			font-weight: bold;
			padding: 60px 0px;
			text-align: left;
			width: 75%;
			margin: 0 auto;

			h2 {
				margin-bottom: 30px;
			}

			.but-ifs-rows .col-sm-6 {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding-bottom: 10px;
				margin-bottom: 10px;
			}

			.collection-container {
				margin-bottom: 10px;
				float: left;
				width: calc(100% - 60px);

				input {
					margin-bottom: 15px;
				}
			}

			.finance,
			.sale-own-house,
			.attachments-noted,
			.nmg {
				background-color: $primary-background-color-light;
				border: 1px solid $primary-background-color-25;
				padding: 30px;
				margin-bottom: 10px;

				.input-group-text {
					background-color: $primary-color;
					color: white;
				}
			}

			input {
				font-family: 'Slabo 27px', serif;
				border: 1px solid silver;
				font-size: 1em;
				text-align: left;
				padding: 15px;
				width: calc(100% - 60px);
				float: left;
			}

			.btn.btn-secondary {
				margin: 30px 0;

				&.add {
					margin: 0;
					float: right;
					display: block;
					padding: 0;
					width: 40px;
					height: 40px;

					i {
						right: 11px;
					}
				}
			}

			.radio {
				width: 45%;
				float: left;

				input {
					float: left;
					width: 24px;
					margin-top: 5px;
				}
			}

			textarea {
				font-family: 'Slabo 27px', serif;
				border: 1px solid silver;
				font-size: 1em;
				text-align: left;
				padding: 15px;
				border-radius: 10px;
				width: 100%;
				min-height: 200px;
			}
		}
	}

	.dashboard-right {
		.person-data {
			position: relative;
			font-size: 14px;
			padding: 15px;

			&:before {
				position: absolute;
				top: 0;
				right: -100%;
				bottom: 0;
				width: 100%;
				background-color: $secondary-background-color-25;
				content: '';
				z-index: -1;
			}

			p {
				margin: 0;
				padding: 0;
			}

			h5 {
				font-size: 1em;
			}

			.btn {
				position: relative;
				margin: 0;
				margin-top: 15px;
				padding: 15px 0;
				width: 100%;

				&.btn-secondary {
					position: relative;

					&:after {
						position: absolute;
						font-family: 'Font Awesome 5 Pro';
						font-style: normal;
						font-weight: normal;
						text-decoration: inherit;
						top: 25%;
						right: 15px;
						content: '\f044';
					}
				}

				&.logout {
					background-color: $red-background-color;

					&:after {
						position: absolute;
						font-family: 'Font Awesome 5 Pro';
						font-style: normal;
						font-weight: normal;
						text-decoration: inherit;
						top: 25%;
						right: 15px;
						content: '\f2f5';
					}
				}

			}
		}
	}

	.bid-history {
		margin-top: 30px;
		padding: 15px 0;

		h4 {
			width: 100%;
			display: block;
			font-size: 1.4em;
			text-align: center;
		}

		h5 {
			font-size: 1em;
		}

		&.bg-light-blue {
			background-color: $primary-background-color-light;
		}

		table {
			font-size: 14px;

			td {
				border: none;
				border-bottom: 3px solid $primary-background-color-light;
				padding: 10px 15px;
				vertical-align: middle;

				&.date {
					background-color: $primary-background-color;
					color: white;
					width: 50px;
					padding: 5px;
					font-size: 14px;
					text-align: center;

					.time {
						display: block;

					}
				}
			}
		}
	}
}

table {
	tr {
		td {
			.bid {
				font-weight: bold;
			}

			.name {
				font-size: 12px;
			}
		}
	}
}

/* checkbox */

/* Customize the label (the container) */
.checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pref-date .checkbox-container {
	padding-left: 0px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 1;
	cursor: pointer;
    top: 10px;
    left: 0;
}


.dashboard .btn.btn-secondary {
	margin: 30px 0;
}

@media (min-width: 320px) {
	.dashboard {
		.bg-dark-blue {
			height: 400px;
			position: relative;
		}
		.kk {
			padding: 60px;
		}
	}
}

@media (min-width: 992px) {
	.dashboard {
		.bg-dark-blue {
			height: 100%;
		}

		.kk {
			padding: 30px;
			padding-left: 0px;
		}

		.btn.btn-secondary a {
			font-size: 12px;
		}
	}
}

@media (min-width: 1400px) {
	.dashboard {
		.btn.btn-secondary a {
			font-size: 14px;
		}
	}
}

@media (min-width: 1600px) {
	.dashboard {
		.btn.btn-secondary a {
			font-size: 18px;
		}
	}
}

/* custom checkbox */

.form-check-inline input[type=checkbox] {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it
	z-index: 2;
	width: 24px;
	height: 24px;

	&:hover {
		cursor: pointer;
	}

	& + span {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	// Box.
	& + span:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 24px;
		height: 24px;
		background: #eee;
		border-radius: 3px;
		border: 1px solid #ddd;
	}

	// Box hover
	&:hover + span:before {
		background: $primary-color;
	}

	// Box focus
	&:focus + span:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}

	// Box checked
	&:checked + span:before {
		background: $primary-color;
	}

	// Disabled state label.
	&:disabled + span {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + span:before {
		box-shadow: none;
		background: #ddd;
	}

	// Checkmark. Could be replaced with an image
	&:checked + span:after {
		content: '';
		position: absolute;
		left: 7px;
		top: 12px;
		background: white;
		width: 3px;
		height: 3px;
		box-shadow: 2px 0 0 white,
		4px 0 0 white,
		4px -2px 0 white,
		4px -4px 0 white,
		4px -6px 0 white,
		4px -8px 0 white;
		transform: rotate(45deg);
	}
}
