/* Hide the browser's default checkbox */
#register_mortgageAdvice,
#register_sellOwnHouse,
#register_intendedEnduser,
#register_olderThan35,
#register_hasPartner,
#register_partnerOlderThan35,
.account-delete {
     input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $white;
      border: 1px solid $primary-background-color-25;
    }
    input:checked ~ .checkmark {
      background-color: #78ff00;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
}
#register_mortgageAdvice .form-check .form-check-label,
#register_sellOwnHouse .form-check .form-check-label,
#register_intendedEnduser .form-check .form-check-label,
#register_olderThan35 .form-check .form-check-label,
#register_hasPartner .form-check .form-check-label,
#register_partnerOlderThan35 .form-check .form-check-label{
    margin: 0 15px;
}
