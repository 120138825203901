section.aanbod{
    margin-top: 60px;
	margin-bottom: 190px;
	position: relative;
	height :500px;

    &:before{
        position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	width :50%;
    	content: '';
    	background-color: $primary-background-color-25;
    	z-index: -1;
    }
    .bg-light-blue{
        height :500px;
        background-color: $primary-background-color-light;
    }
    .content-title{
    	position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
    	left: 2%;
    	background-color: white;
    	padding: 30px;
    	width: 280px;
    	z-index: 2;
        .title-line{
            position: absolute;
            display: block;
            height: 4px;
            width: 60px;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            &.blue{
                background-color: $primary-background-color;
            }
        }
    }
    .content-title:after{
    	position: absolute;
    	bottom: -30px;
    	left: 0;
    	right: 0;
    	content: '';
    	background-color: $primary-background-color-25;
    	height: 30px;
    }
    .swiper-button-prev{
        display: none;
    }
}

.filter-bar.page{
    .content-title{
        h1{
            font-family: 'ITC Avant Garde Gothic LT';
            font-size:2em;
        }
    }
}
.detail-title-bar{
    .content-title{
        h1{
            font-family: 'ITC Avant Garde Gothic LT';
            font-size:2em;
        }
    }
}
section.soon{
    margin-top: 120px ;
	margin-bottom: 190px;
	position: relative;
	height :500px;

    &:before{
        position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	width :50%;
    	content: '';
    	background-color: $secondary-background-color-25;
    	z-index: -1;
    }
    .bg-light-gold{
        height :500px;
        background-color: $secondary-background-color-light;
    }
    .content-title{
    	position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
    	left: 60px;
    	background-color: white;
    	padding: 30px;

    	z-index: 2;
        &.gold{
            color: $secondary-color;
        }
        .title-line{
            position: absolute;
            display: block;
            height: 4px;
            width: 60px;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            &.gold{
                background-color: $secondary-background-color;
            }
        }
    }
    .content-title:after{
    	position: absolute;
    	bottom: -30px;
    	left: 0;
    	right: 0;
    	content: '';
    	background-color: $secondary-background-color-25;
    	height: 30px;
    }
    .swiper-button-prev,
    .swiper-button-prev-2 {
        display: none;
    }
}
section.filter-bar.page{
    margin-top: 200px;
	position: relative;
    &:after{
        position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	width :50%;
    	content: '';
    	background-color: $primary-background-color-25;
    	z-index: -1;
    }
    .bg-light-blue{
        position: relative;
        height: 160px;
        background-color: $primary-background-color-light;
        .filter-bar-input{
            line-height: 160px;
            margin-top: 60px;
            select{
                width: 100%;
                background-color: $primary-background-color;
                color: white;
                border-radius: 0px;
                height: 50px;
            }
        }
    }

}
    .aanbod-list{
    .card{
        margin-top: 30px;
        box-shadow: 0 0 30px rgba(0,0,0,0.25);
    }
}

section.detail-title-bar{
    margin-top: 200px;
	position: relative;

    &:before{
        position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	width :50%;
    	content: '';
    	background-color: $primary-background-color-25;
    	z-index: -1;
    }

    .bg-light-blue{
        position: relative;
        height: 160px;
        background-color: $primary-background-color-light;
        .filter-bar-input{
            line-height: 160px;
            margin-top: 60px;
            select{
                width: 100%;
                background-color: $primary-background-color;
                color: white;
                border-radius: 0px;
            }
        }
    }

    .content-title{
        .breadcrumb {
            margin-top: 0px;
            margin-bottom: 0;
            text-transform: uppercase;
            font-family: 'ITC Avant Garde Gothic LT';
            font-size: 0.6em;
        }

    	position: absolute;
    	top: 42%;
    	transform: translateY(-50%);
    	left: 2%;
    	background-color: white;
    	padding: 15px 30px;
    	width: auto;
    	z-index: 2;
        display :flex;

        .breadcrumb-nav li:last-child {
            font-size: 2em;
            color: $title-color;
        }

        .title-line{
            position: absolute;
            display: block;
            height: 4px;
            width: 60px;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            &.gold{
                background-color: $secondary-background-color;
            }
        }
    }

    .content-title:after{
    	position: absolute;
    	bottom: -30px;
    	left: 0;
    	right: 0;
    	content: '';
    	background-color: $primary-background-color-25;
    	height: 30px;
    }
}


/* responsive */
@media (min-width: 320px) {
    .swiper-aanbod, .swiper-veiling{
        width: 320px;
        margin: 0 auto;
        overflow: hidden;
        padding: 45px 0;
    }
    section.aanbod{
        margin-top: 60px;
        &:before{
            display: none;
        }
        height: auto;
        .bg-light-blue{
            height: auto;
        }
        .content-title{
            h2{
                font-size: 1.2rem;
                text-align: center;
            }
            width: 100%;
            position: static;
            .title-line{
                display: none;
            }
        }
    }

    section.soon{
        margin-bottom: 100px;
        &:before{
            display: none;
        }
        height: auto;
        .bg-light-gold{
            height: auto;
        }
        .content-title{
            h2{
                font-size: 1.2rem;
                text-align: center;
            }
            width: 100%;
            position: static;
            .title-line{
                display: none;
            }
        }
    }

    section.filter-bar.page{
        margin-top: 100px;
        &:after{
            display: none;
        }
        .content-title{
        	position: static;
        	background-color: white;
        	padding: 15px 30px;
        	width: 100%;
            text-align: center;
        	z-index: 2;
            .title-line{
                display: none;
            }
        }
    }
    .aanbod-header-block .high #menu ul li a{
        color: white;
    }

}
@media (min-width: 768px) {
    .swiper-aanbod, .swiper-veiling{
        width: 600px;
    }
}
@media (min-width: 992px) {
    .swiper-aanbod, .swiper-veiling{
        width: 600px;
    }
    section.aanbod{
        margin-top : 60px;
        &:before{
            display: block;
        }
        height: 500px;
        .bg-light-blue{
            height: 500px;
        }
        .content-title{
            h2{
                font-size: 1.6rem;
                text-align: left;
            }
            width: 200px;
            position: absolute;
            left: 0;
            .title-line{
                display: none;
            }
        }
    }
    section.soon{
        margin-bottom: 190px;
        &:before{
            display: block;
        }
        height: 500px;
        .bg-light-gold{
            height: 500px;
        }
        .content-title{
            width: 200px;
            left: 0;
            position: absolute;
            h2{
                font-size: 1.6rem;
                text-align: left;
            }
            .title-line{
                display: none;
            }
        }
    }
    section.filter-bar.page{
        margin-top: 200px;
        &:after{
            display: block;
        }
        .content-title{
        	position: absolute;
        	top: 42%;
        	transform: translateY(-50%);
        	left: 2%;
        	background-color: white;
        	padding: 15px 30px;
        	width: 280px;
        	z-index: 2;
            &:after{
                position:  absolute;
                left: 0;
                right: 0;
                bottom: -30px;
                height: 30px;
                background-color: $primary-background-color-25;
                content: '';
            }
            .title-line{
                position: absolute;
                display: block;
                height: 4px;
                width: 60px;
                right: -30px;
                top: 50%;
                transform: translateY(-50%);
                &.gold{
                    background-color: $secondary-background-color;
                }
            }
        }
    }
    .aanbod-header-block {
        .high{
            #menu ul li a{
                color: $primary-color;
            }
            .phonenumber{
                color: $primary-color;
            }
        }

    }


}
@media (min-width: 1200px) {
    .swiper-aanbod, .swiper-veiling{
        width: 800px;
    }
}

@media (min-width: 1400px) {
    .swiper-aanbod, .swiper-veiling{
        width: 900px;
    }
    section.aanbod{
        &:before{
            display: block;
        }
        height: 500px;
        .bg-light-blue{
            height: 500px;
        }
        .content-title{
            h2{
                font-size: 1.6rem;
                text-align: left;
            }
            width: 200px;
            position: absolute;
            left: 2%;
            .title-line{
                display: block;
            }
        }
    }
    section.soon{
        &:before{
            display: block;
        }
        height: 500px;
        .bg-light-gold{
            height: 500px;
        }
        .content-title{
            width: 200px;
            left: 2%;
            position: absolute;
            h2{
                font-size: 1.6rem;
                text-align: left;
            }
            .title-line{
                display: block;
            }
        }
    }
}
@media (min-width: 1600px) {
    .swiper-aanbod, .swiper-veiling{
        width: 1000px;
    }
    section.aanbod{
        .content-title{
            width: 280px;
        }
    }
    section.soon{
        .content-title{
            width: 280px;
        }
    }
}
