@import url('https://fonts.googleapis.com/css?family=Lato');

html{
    background: transparent url('/build/images/arievanderlee-back.jpg') no-repeat top center;
    body{
        font-family: 'Lato', sans-serif;
        color: #333;
        h1, .filter-bar.page .content-title h1{
            font-family: 'Lato', sans-serif;
            color: $primary-color;
        }
        h2, h3, h4, h5, h6{
            font-family: 'Lato', sans-serif;
        }
        article.card time{
            font-family: 'Lato', sans-serif;
        }
    }

    .detail-header-image{
        .detail-header-image-card__count{
            time{
                font-family: 'Lato', sans-serif;
            }
        }
    }

    section.detail-title-bar {
        .content-title {
            .breadcrumb {
                font-family: 'Lato', sans-serif;

            }
        }
    }
}

header .logo{
    position: relative;
    width: 100%;
    .logo-img{
        margin: 0 auto;
    }
}
header .logo .nvm{
    display: none;
}
.aanbod-header-block header .phonenumber{
    display: inline-block;
    text-align: right;
    left: 0px;
    top: 15px;
    right: auto;
    overflow: hidden;
    background-color: $secondary-color;
    padding: 15px 30px;
    &:hover{
        background-color: $primary-color;
    }
    @media (min-width: 992px) {
        left: auto;
        right: 210px;
        top: 15px;
        overflow: auto;
    }
    a{
        color: white;
        font-size: 0rem;
        width :60px;
        height: 54px;
        @media (min-width: 992px) {
            font-size: 1rem;
            height: auto;
            width: auto;
        }
    }
    .fas.fa-phone{
        color: white;
        fill: white;
        font-size: 1rem;
    }
}
.aanbod-header-block header.narrow .phonenumber{
    right: 149px;
}
.row.top-detail .top-detail-right .alert.alert-warning{
    color: yellow;
}


section.detail-title-bar {
    .content-title {
        .breadcrumb {
            font-family: 'Lato', sans-serif;

        }
        .breadcrumb-nav li:last-child{
            color: $primary-color;
        }
    }
}

//section.detail-title-bar .content-title .breadcrumb-nav li:last-child{
//    color: $primary-color;
//}
article.card .card__title{
    color: black;
    text-shadow: none;
    font-weight: bold;
    background-color: rgba(255,255,255,1);
    font-size: 12px;
}


.card__logo {
    display: none;
}
.detail-header-image-card__count .countdown-wrapper .card__logo {
    display: block;
}

/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #333;
            }
            .footer-funda svg{
                fill: #333;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #333;
            }
            .footer-nrvg svg{
                fill: #333;
            }
        }
    }
}
