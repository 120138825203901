.menu-container{
    width: 100%;
}
nav ul{
    float: right;
    position: relative;
    ul{
        position: relative;
        display: none;
        margin-top: -15px;
        @include all();
    }
    li{
        position: relative;
        display: inline-block;
        float: left;
        &.active{
            border-bottom: 3px solid $secondary-color;
        }
        &:not(:first-child){
            margin-left :30px;
        }
        &:last-child{
            margin-right: 65px;
        }
        a{
            text-transform: uppercase;
            display: inline-block;
            outline: none;
            color: white;
            font-family: 'ITC Avant Garde Gothic LT';
            letter-spacing: 1px;
            text-shadow: 1px 1px 3px $primary-background-color-50;
        }
        &:hover{
            ul{
                position: absolute;
                display :block;
                margin: 0;
                width: 240px;
                background-color: #fff;
                box-shadow: 0px 5px 30px rgba(0,0,0,0.25);
                padding: 15px;
                @include all();

            }
            li{
                margin: 0;
                padding: 0;
                line-height: 40px;
            }
        }
        li{
            float: none;
            margin-left: 0px;
            a{
                color: $primary-color;
            }
        }
    }
}
.hidden{
    display :none;
}
.high #menu ul{
    margin-top : 90px;
    @include all();
    ul{
        margin-top: 0;
    }
}
.narrow #menu ul{
    margin-top: 8px;
    margin-right: 160px;
    @include all();
    ul{
        margin-right: 0px;
        margin-top: 0px;
    }
    li{
        &:last-child{
            margin-right: 15px;
        }
        a{
            text-shadow: none;
        }
    }
}
.narrow #menu .nav-logout ul{
    li{
        &:last-child{
            margin-right: 55px;
        }
    }
}

.high #menu ul li a{
    color: white;
    @include all();
}
.high #menu nav ul li li a{
    color: $primary-color;
}
.narrow #menu ul li a{
    color: $primary-color;
    @include all();
}


@media (max-width: 992px){

    .nav-wrapper{
        position: relative;
        top: 0;
        left :0;
        width :100%;
        height: 100%;
        z-index: -1;
        background-color: $primary-background-color;
        background-image: url("../../images/menu-logo.png");
        background-repeat: no-repeat;
        background-position: 140% 140%;
        opacity: 0;
        @include all();
        ul{
            position: absolute;
            top :50%;
            left :50%;
            transform: translate(-50% , -70%);
            text-align: center;
            li{
                display: block;
                float: none;
                width: 100%;
                text-align: center;
                &:hover{
                    box-shadow: none;
                }
                &.hover{
                    margin: 0;
                    padding: 0;
                    box-shadow: none;
                    li{
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                        a{
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                        }
                    }
                }
                a{
                    line-height: 40px;
                    color: white !important;
                }
                ul{
                    display: block;
                    position: static;
                    margin: 0;
                    margin-bottom :30px;
                    padding: 0;
                    top : 0%;
                    left : 0%;
                    transform: translate(0% , 0%);
                    li{
                        float: none;
                        a{
                            color: white !important;
                            font-family: 'Slabo 27px', serif;
                        }
                    }

                }
            }
        }

        .mobile-phonenumber{
            display: block;
            color: white;
            margin: 30px;
            a{
                color: white;
                font-size: 1.2em;
                i{
                    margin-right: 10px;
                }
            }
        }
    }
    nav ul{
        float: none;
        li{
            display: inline-block;
            float: none;
            &:not(:first-child){
                margin-left : 0px;
            }
            &:last-child{
                margin-right: 0px;
            }
            a{
                display: inline-block;
                outline: none;
                color: white;
                font-family: 'ITC Avant Garde Gothic LT';
            }
        }
    }
    .nav-btn{
        &.reseller-nav-btn {
            display: none;
        }
        position: fixed;
        right: 70px;
        top :15px;
        display: block;
        width: 48px;
        height: 48px;
        cursor: pointer;
        z-index: 10000;
        i{
            display: block;
            width: 20px;
            height: 2px;
            background-color: $primary-background-color;
            border-radius: 2px;
            margin-left: 14px;
            &:nth-child(1){
                margin-top: 16px;
            }
            &:nth-child(2){
                margin-top: 4px;
                opacity: 1;
            }
            &:nth-child(3){
                margin-top: 4px;
            }
        }
    }
    #nav:checked + .nav-btn{
        transform: rotate(45deg);
        right: 15px;
    }
    #nav:checked + .nav-btn i{
        background-color: red;
        @include all();
    }
    #nav:checked + .nav-btn i:nth-child(1){
        transform: translateY(6px) rotate(180deg);
    }
    #nav:checked + .nav-btn i:nth-child(2){
        opacity: 0;
        @include all();
    }
    #nav:checked + .nav-btn i:nth-child(3){
        transform: translateY(-6px) rotate(90deg);
        @include all();
    }
    #nav:checked ~ .nav-wrapper{
        position: fixed;
        height: 100vh;
        opacity: 1;
        z-index: 9990;
        @include all();
    }
    #nav:checked ~ .nav-wrapper ul li a{
        opacity: 1;
    }
    .high #menu ul li a,
    .narrow #menu ul li a{
        color: white;
    }
    nav .nav-wrapper ul li.active {
        border: none !important;
        a{
            border-bottom: 3px solid $secondary-color;
        }
    }
    nav .nav-wrapper ul *{
        position: static !important;
        background-color: transparent !important;
    }
    nav .nav-wrapper ul li{
        &.hover{
            position: static;
            width: auto;
            margin-bottom: 30px;
            a{
                position: static;
                border-bottom: 3px solid $secondary-color;
            }

        }
    }
    nav .nav-wrapper ul li{
        &.active{
            li{
                a{
                    color: white;
                    border-bottom: 3px solid $primary-color;
                }
                &.active{
                    a{
                        border-bottom: 3px solid $secondary-color;
                    }
                }
            }
        }
    }
    nav .nav-wrapper ul:hover li{
        position: static;
    }
}
@media (min-width: 992px){
    .mobile-phonenumber{
        display: none;
    }
    #menu ul li a{
            font-size: 0.8em;
    }
    nav ul li:not(:first-child) {
        margin-left: 15px;
    }

}
@media (min-width: 1200px){
    #menu ul li a{
            font-size: 1em;
    }
    nav ul li:not(:first-child) {
        margin-left: 30px;
    }

}

.aanbod-header-block .high #menu ul li a{
    color: $primary-color;
}
