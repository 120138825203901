header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    @include all();
    .phonenumber{
        position: absolute;
        a{
            color: $primary-color;
        }
    }
    &.high{
        height: 200px;
    }
    &.narrow{
        background-color: #fff;
        height: 90px;
        box-shadow: 0 0 25px rgba(0,0,0,0.2)
    }
    .logo{
        width :32%;
        position: absolute;
        top: 15px;
        left: 2%;
        .logo-img{
            height: 134px;
            @include all();
        }
        .nvm{
            position: absolute;
            top: 95px;
            left: 200px;
            height: 40px;
            float: left;
        }
    }
}
.btn-login{
    position: absolute;
    display : inline-block;
    color: #fff;
    background-color: $primary-background-color;
    font-size: 1em;
    padding: 15px 30px;
    @include all();
    i{
        margin-left :5px;
    }
    &:hover{
        background-color: $secondary-background-color;
        color: white;
    }
}

/* responsive */
@media (min-width: 320px) {
    .logo{
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
            &.logo-img{
                height: 50px;
            }
        }
    }
    .btn-login{
        padding: 15px 20px;
        top: 15px;
        right: 0px;
        span{
            display: none;
        }
    }
    header{
        &.high{
            height: 90px;
            background-color: white;
        }
        .logo{
            .nvm{
                top: 15px;
                left: 170px;
                height: 24px;
            }
        }
        .phonenumber{
            display: none;
        }
    }
}

@-webkit-keyframes pulsate {
    0% {
        opacity:1;
        background-color: $secondary-background-color;
    }
    50% {
        opacity: 1.0;
        background-color: $primary-background-color;
    }
    100% {
        opacity: 1;
        background-color: $secondary-background-color;
    }
}

.pulsate {
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.5;
}
@media (min-width: 768px) {

}
@media (min-width: 992px) {
    header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        @include all();
        .phonenumber{
            display: block;
            left: 60px;
            top: 160px;
            position: absolute;
            color: $primary-color;
            @include all();
            i{
                margin-right: 5px;
            }
        }
        &.high{
            height: 200px;
            background-color: transparent;
            @include all();
            .logo-img.dekstop{
                height: 134px;
                @include all();
            }
            .logo{
                .nvm{
                    top: 100px;
                    left: 200px;
                }
            }
        }
        &.narrow{
            .logo-img{
                height: 60px;
                @include all();
            }
            .nvm{
                top: 36px;
                left: 90px;
                height: 24px;
                @include all();
            }
            .phonenumber{
                color: $primary-color;
                top: 50px;
                left: 170px;
                @include all();
            }
        }
    }
    .logo{
        .desktop{
            display: block;
        }
        .mobile{
            display: none;
        }
    }
    .btn-login{
        padding: 15px 30px;
        span{
            display: inline-block;
        }
    }
    .high .btn-login{
        top: 15px;
        right: 60px;
        @include all();
    }
    .narrow .btn-login{
        top: 15px;
        right: 0px;
        @include all();
    }
}
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {

}
@media (min-width: 1600px) {

}
