.form-horizontal {
	input::-webkit-calendar-picker-indicator {
		display: none;
	}

	input[type="date"]::-webkit-input-placeholder {
		visibility: hidden !important;
	}

	.form-error {
		color: red;
	}

	.row.pref-date {
		button.btn {
			&.disabled {
				opacity: 1;
			}

			&:disabled {
				opacity: 1;
			}

			i {
				top: 15px;
				right: 15px;
				z-index: 5;
			}
		}
	}

	.form-control {
		border-radius: 7px;
		padding: 15px;
		margin-bottom: 15px;
		&.error {
			border: 1px solid red!important;
		}
	}

	.form-group.row {
		a {
			color: gray;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
				color: $primary-color;
			}
		}

		.fas.fa-info-circle {
			color: $primary-color;
		}
	}

}

.account-delete {
	display: inline-block;
	padding: 15px 30px;

	label {
		margin-bottom: 0;
	}

	.checkmark {
		/*border: 1px solid gray;*/
	}
}

.delete-account {
	padding: 15px;
	background-image: url("../../images/red-line.gif");
	background-repeat: repeat;
	border: 3px solid #ffb5b5;
	float: left;
	width: 100%;
	border-radius: 15px;
}

.reservation-viewing {
	height: auto !important;
	margin-bottom: 0;
	background-repeat: repeat;
	float: left;
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;

	p {
		// background-color: #c42000;
	    color: #fff;
		padding: 20px 50px;
		font-weight: 900;
		font-size: 18px;
		margin-bottom: 0;
	}
}

.filter-bar-input {
	.form-control {
		padding: 0 15px;
	}
}

.login-panel {

}

/* verkoop form */
.seller-form {
	label {
		font-weight: 900;
	}

	.form-control {
		border-radius: 7px;
		padding: 15px;
		margin-bottom: 15px;
		font-weight: 900;
	}

	select.form-control {
		padding: 0 15px;

		&:not([size]):not([multiple]) {
			height: 56px;
		}
	}

	.section.inhoud {
		padding: 30px 0;
	}

	.bg-light-gold {
		position: relative;
		background-color: #f4ecdf;

		&:before {
			position: absolute;
			top: 0;
			right: -25%;
			bottom: 0;
			width: 50%;
			content: '';
			background-color: rgba(185, 130, 40, 0.25);
			z-index: -1;
		}
	}

	.bg-light-blue {
		position: relative;
		background-color: #dedee7;

		&:before {
			position: absolute;
			top: 0;
			right: -25%;
			bottom: 0;
			width: 50%;
			content: '';
			background-color: rgba(32, 32, 92, 0.25);
			z-index: -1;
		}
	}
}

form[name="register"]{
	#register_mortgageAdvice,
	#register_sellOwnHouse,
	#register_intendedEnduser,
	#register_olderThan35,
	#register_hasPartner,
	#register_partnerOlderThan35{
        .form-check{
            display: inline-block;
        }
    }
    .account-delete{

    }
	.register_form-contentblock{
		background-color: $primary-background-color-15;
		padding: 30px 0;
		border-radius: 10px;
		margin: 0 0 20px;
		.register_search_profiles {
			width: 60%;
			margin: 0 auto;
		}
		> span {
			&:after {
				content:"*";
			}
		}
        .mortgage-logo{
                width: 200px;
            @media (min-width: 992px) {            
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
	}
}
.search-form{
    .register_search_profiles{
        .form-row:nth-of-type(2){
            .collection-type{
                display: block;
                width: 100%;
                .collection-type-container{
                    display: block;
                    width: 100%;
                    .collection-type-entry{
                        display: block;
                        width :100%;
                        position: relative;
                        background-color: $primary-background-color-15;
                        border-radius: 5px;
                        padding: 20px;
                        padding-top: 40px;
                        margin: 0;
                        margin-top: 40px;
                        @media (min-width: 992px) {
                            margin: 40px;
                            width: calc(100% - 80px);
                            padding: 40px;
                        }
                        .col-10{
                            text-align: left;
                            width: 100%;
                            max-width: 100%;
                            select{
                                padding: 0;
                            }
                        }
                        .col-2.text-center{
                            display: block;
                            position: absolute;
                            top: -15px;
                            left: 50%;
                            width :100%;
                            max-width: 100%;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                            @media (min-width: 992px) {
                                top: -30px;
                                right: 20px;
                            }
                            button{
                                position: relative;
                                padding: 10px 20px;
                                padding-right: 40px;
                                &:after{
                                    position: absolute;
                                    right: 15px;
	                                top: 10px;
                                    font-family: 'Font Awesome 5 Pro';
                                    font-weight: 900;
                                    content: '\f2ed';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
