.breadcrumb {
    background-color: transparent;
    margin-top: 30px;
    h1{
        font-family: 'ITC Avant Garde Gothic LT';
    }

    li {
        padding-left :30px;
        position: relative;
        color: silver;

        @include flexbox();
        @include flex-flow (column nowrap);
        @include justify-content(center);

        a {
            color: $secondary-color;
        }

        &:before {
            font-family: 'Font Awesome 5 Pro';
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            content: '\F054';
            position: absolute;
            font-size: 0.8em;
            top: 50%;
            transform: translateY(-50%);
            color: silver;
            z-index: 1;
            margin-left: -15px;
        }

        &:nth-of-type(1) {
            padding-left: 0px;
            &:before{
                content:'';
            }
        }
    }
}

@media (min-width: 320px) {
    .inhoud {
        .page-breadcrumb {
            display: block;
        }
    }
}

@media (min-width: 992px) {
    .inhoud {
        .page-breadcrumb {
            display: none;
        }
    }
}
