html{
    min-height: 100%;
    height: 100%;
    body.error{
        position: relative;
        background-color: #eee;
        min-height: 100%;
        height: 100%;
        color: #333;
        .wrapper{
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .main{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 90%;
                background-color: white;
                transform: translate(-50%, -50%);
                border-radius: 10px;
                box-shadow: 0 10px 50px rgba(0,0,0,0.25);
                padding: 60px;
                @media (min-width: 992px) {
                    width: 50%;
                }
                a{
                    color: #333;
                    text-decoration: underline;
                    &:hover{
                        text-decoration: none;
                    }
                }
                .logo{
                    display: block;
                    text-align: center;
                    margin-top: -30px;
                    margin-bottom: 30px;
                    img{
                        width :200px;
                        margin: 0 auto;
                    }
                }
                pre{
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 10px;
                    width :100%;
                }
            }
        }
    }
}
