article.card{
    position: relative;
    text-align: center;
    .btn.btn-secondary{
        margin: 5px;
        display: block;
    }
    .card__info{

        &.openhuis{
            color: $red-color;
            font-weight: bold;
        }
    }
    figure{
        position: relative;
        overflow: hidden;
        img{
            width: 500px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            @include all();
            &:hover{
                width: 600px;
                @include all();
            }
        }

    }
    &.sold{
        .card__count{
            color: $red-color;
        }
        .card__logo{
            position: relative;
            background-color: $red-color;
            z-index: 2;
        }
        figure{
            position: relative;
            overflow: hidden;
            &:after{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: '';
                background-color: $primary-background-color-75;
                z-index: 2;
            }
            .verkocht{
                position: absolute;
                color: white;
                text-transform: uppercase;
                top: -15px;
                right: -65px;
                background-color: $red-background-color;
                z-index: 5;
                transform: rotate(45deg);
                padding : 45px;
                padding-bottom: 0px;
                font-family: 'ITC Avant Garde Gothic LT';
                font-size: 0.8em;
            }
        }
    }
    &.closed{
        .card__logo{
            position: relative;
            z-index: 2;
        }
        figure{
            position: relative;
            overflow: hidden;
            &:after{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: '';
                background-color: $primary-background-color-75
            }
        }
        .card__count{
            color: $red-color;
        }
        .text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            color: white;
            text-shadow: 1px 1px 2px rgba(0,0,0,1);
            z-index: 3;
        }
    }
    &.evaluation{
        .card__logo{
            position: relative;
            z-index: 2;
        }
        figure{
            position: relative;
            overflow: hidden;
            &:after{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: '';
                background-color: $primary-background-color-75
            }
        }
        .card__count{
            color: $red-color;
        }
        .text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            color: white;
            text-shadow: 1px 1px 2px rgba(0,0,0,1);
            z-index: 3;
        }
    }
    &.soon{
        .card__count{
            color: $red-color;
        }
        .card__info{
            color: $red-color;
        }
    }
    figure{
        height: 196px;
        background-color: #dbe2f3;
    }
    p{
        margin: 0;
    }
    .card__title{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        color: white;
        text-align: center;
        padding: 15px;
        background-color: $primary-background-color-50;
        text-shadow: 1px 1px 2px rgba(0,0,0,1);
        z-index: 2;
    }
    .card__logo{
        position: relative;
        background-color: #fff;
    	padding: 15px;
    	width: 65px;
    	height: 65px;
    	text-align: center;
    	border-radius: 4px;
    	box-shadow: 0 0 15px rgba(0,0,0,0.25);
    	margin: 0 auto;
    	margin-top :-50px;
        z-index: 2;
    }
    time{
    	font-family: 'ITC Avant Garde Gothic LT';
    	font-size: 1.5em;
    	margin: 15px;
    }
    .card__bid{
        background-color: $primary-background-color;
    	color: white;
    	padding: 10px;
    }
    .bid-high, .bid-low{
    	width :50%;
    	float: left;
    }
    .bid-high{
    	font-size: 1.3em;
    }
    .card__info{
    	color: gray;
    	padding: 15px;
    }
}
